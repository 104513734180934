var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm",
          attrs: { title: "기본정보", noMarginPadding: true },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "과목명",
                    value: _vm.educationInfo.educationName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "교육과정",
                    value: _vm.educationInfo.educationCourseName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "교육종류1",
                    value: _vm.educationInfo.educationKindCdLargeName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "교육종류2",
                    value: _vm.educationInfo.educationKindCdSmallName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "사업장",
                    value: _vm.educationInfo.plantName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "주관부서",
                    value: _vm.educationInfo.departmentDeptName,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm mobilevod mobilevod-hide",
          attrs: { title: "동영상 시청", noMarginPadding: true },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "player-center text-center col-12" },
              [
                _c("video-player", {
                  ref: "videoPlayer",
                  attrs: { options: _vm.playerOptions },
                  on: {
                    playing: _vm.onPlayerStart,
                    play: _vm.onPlay,
                    pause: _vm.onPause,
                    ended: function ($event) {
                      return _vm.onPlayerEnded($event)
                    },
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "vod-label-layer col-12" }, [
              !_vm.disabled &&
              _vm.educationInfo.educationMethodCd == "EMC999999"
                ? _c("div", { staticClass: "vod-label-div text-center" }, [
                    _vm._v(
                      "재생 시간 : " +
                        _vm._s(_vm.vodPlayDuration) +
                        " / " +
                        _vm._s(_vm.vodTotalDuration)
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "vod-label-div text-center" },
                [
                  _vm.isFirstPlay &&
                  !_vm.isPlay &&
                  !_vm.isEduEnd &&
                  !_vm.disabled
                    ? _c("c-btn", {
                        staticClass: "full-width",
                        attrs: {
                          label: "처음부터 재생",
                          icon: "skip_next",
                          color: "orange",
                        },
                        on: { btnClicked: _vm.onPlayerPlayFirst },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vod-label-div text-center" },
                [
                  !_vm.isEduEnd && !_vm.disabled
                    ? _c("c-btn", {
                        staticClass: "full-width",
                        attrs: {
                          label: _vm.playLabel,
                          icon: _vm.playIcon,
                          color: _vm.playColor,
                        },
                        on: { btnClicked: _vm.onPlayerPlay },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "c-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.isEnd || _vm.educationInfo.educationMethodCd != "EMC999999",
              expression:
                "(isEnd || educationInfo.educationMethodCd != 'EMC999999')",
            },
          ],
          staticClass: "cardClassDetailInfo",
          attrs: { title: "서명 및 의견" },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-12 mb-5" },
              [
                _c("div", { staticClass: "items-center all-pointer-events" }, [
                  _c(
                    "b",
                    [
                      _c("font", { attrs: { id: "signatureTitle" } }, [
                        _vm._v("  서명"),
                      ]),
                    ],
                    1
                  ),
                ]),
                _c("VueSignaturePad", {
                  ref: "signaturePad",
                  attrs: { id: "signature", options: _vm.options },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12 mt-3" },
              [
                _c("c-textarea", {
                  attrs: {
                    disabled: _vm.disabled,
                    label: "의견",
                    name: "opinionContent",
                  },
                  model: {
                    value: _vm.educationInfo.opinionContent,
                    callback: function ($$v) {
                      _vm.$set(_vm.educationInfo, "opinionContent", $$v)
                    },
                    expression: "educationInfo.opinionContent",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "p-3" }, [
        _vm.disabled
          ? _c("div", { staticClass: "vod-label-div text-center" }, [
              _vm._v("이수완료 : " + _vm._s(_vm.educationInfo.evaluationDate)),
            ])
          : _vm._e(),
        _vm.disabled
          ? _c("div", { staticClass: "vod-label-div text-center" }, [
              _vm._v("교육완료 또는 이수완료 되었습니다."),
            ])
          : _vm._e(),
        !_vm.disabled && _vm.educationInfo.educationMethodCd == "EMC999999"
          ? _c("div", { staticClass: "vod-label-div-text" }, [
              _vm._v(
                " ※ 재생/정지/완료 버튼을 클릭하지 않고 창을 닫을 경우 동영상 재생이력이 저장되지 않습니다. "
              ),
            ])
          : _vm._e(),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c("div", { staticClass: "popup-bottom-bar" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  (_vm.isEnd ||
                    _vm.educationInfo.educationMethodCd != "EMC999999") &&
                  !_vm.disabled,
                expression:
                  "(isEnd || educationInfo.educationMethodCd != 'EMC999999') && !disabled",
              },
            ],
            staticClass: "popup-bottom-bar-btngroup",
          },
          [
            _c(
              "q-btn-group",
              { attrs: { outline: "" } },
              [
                _c("c-btn", {
                  attrs: {
                    flat: "",
                    label: "서명지우기",
                    icon: "remove",
                    color: "red",
                  },
                  on: { btnClicked: _vm.eraseSignature },
                }),
                _c("c-btn", {
                  attrs: {
                    flat: "",
                    label: "이수완료",
                    icon: "task_alt",
                    color: "blue",
                  },
                  on: { btnClicked: _vm.eduComplete },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "popup-bottom-bar-close" },
          [
            _c("q-btn", {
              attrs: { flat: "", color: "gray", icon: "arrow_back" },
              on: { click: _vm.closePopUps },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }